@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import url("font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,600,700,300italic,600italic,700italic");

/*
Frequency by Pixelarity
pixelarity.com | hello@pixelarity.com
License: pixelarity.com/license
*/

@import "libs/skel";

@include skel-breakpoints((
xlarge: '(max-width: 1680px)',
large: '(max-width: 1280px)',
medium: '(max-width: 980px)',
small: '(max-width: 736px)',
xsmall: '(max-width: 480px)'
));

@include skel-layout((
reset: 'full',
boxModel: 'border',
grid: ( gutters: 2em ),
conditionals: true,
containers: 1400px,
breakpoints: (
xlarge: (
containers: 1200px,
),
large: (
containers: 960px,
grid: (
gutters: 1.5em
),
),
medium: (
containers: (90%, true),
),
small: (
grid: (
gutters: 1.25em
),
)
)
));

/* Basic */

body {
  background: _palette(bg);

  &.is-loading {
    *, *:before, *:after {
      @include vendor('animation', 'none !important');
      @include vendor('transition', 'none !important');
    }
  }
}

body, input, select, textarea {
  color: _palette(fg);
  font-family: _font(family);
  font-size: 13pt;
  font-weight: _font(weight);
  line-height: 1.65em;
}

a {
  color: _palette(accent1, bg);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

strong, b {
  color: _palette(fg-bold);
  font-weight: _font(weight-bold);
}

em, i {
  font-style: italic;
}

p {
  margin: 0 0 _size(element-margin)/2 0;
  line-height: 1.8;
}

h1, h2, h3, h4, h5, h6 {
  color: _palette(fg-bold);
  font-weight: _font(weight-bold);
  letter-spacing: _font(letter-spacing);
  line-height: 1em;
  margin: (_size(element-margin) * 0.5) 0 (_size(element-margin) * 0.2) 0;
  text-transform: uppercase;

  a {
    color: inherit;
    text-decoration: none;
  }
}

h1 {
  font-size: 2.0em;
  line-height: 1.75em;
}

h2 {
  font-size: 1.75em;
  line-height: 1.5em;
}

h3 {
  font-size: 1.25em;
  line-height: 1.5em;
}

h4 {
  font-size: 1.1em;
  line-height: 1.5em;
}

h5 {
  font-size: 0.9em;
  line-height: 1.5em;
}

h6 {
  font-size: 0.7em;
  line-height: 1.5em;
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

hr {
  border: 0;
  border-bottom: solid 1px _palette(border);

  // This is the *only* instance where we need to rely on margin collapse.
  margin: _size(element-margin) 0;

  &.major {
    margin: (_size(element-margin) * 1.5) 0;
  }
}

blockquote {
  border-left: solid 4px _palette(border);
  font-style: italic;
  margin: 0 0 _size(element-margin) 0;
  padding: 0.5em 0 0.5em 2em;
}

code {
//  border: solid 1px _palette(border);
  color: _palette(fg);
  font-family: _font(family-fixed);
  font-size: 0.9em;
//  margin: 0 0.25em;
 // padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: _font(family-fixed);
  font-size: 0.9em;
  margin: 0 0 _size(element-margin) 0;

  code {
    border: solid 1px _palette(border);
    display: block;
    line-height: 1.75em;
    padding: 1em 1.5em;
    overflow-x: auto;
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Section/Article */

section, article, header {
  &.special {
    text-align: center;
  }
}

header {
  p {
    position: relative;
    margin: 0 0 (_size(element-margin) * 0.75) 0;
  }

  h2 + p {
    font-size: 1.25em;
    margin-top: (_size(element-margin) * -0.5);
    line-height: 1.75em;
  }

  h3 + p {
    font-size: 1.1em;
    margin-top: (_size(element-margin) * -0.4);
    line-height: 1.5em;
  }

  h4 + p,
  h5 + p,
  h6 + p {
    font-size: 0.9em;
    margin-top: (_size(element-margin) * -0.3);
    line-height: 1.5em;
  }

  &.major {
    margin: 0 0 _size(element-margin) 0;

    h1, h2, h3, h4, h5, h6 {
      border-bottom: solid 1px _palette(border);
      display: inline-block;
      margin: 0 0 (_size(element-margin) * 0.4);
      padding: 0 0 (_size(element-margin) * 0.4);
    }

    p {
      font-size: 1.25em;
      margin: 0.1em 0;
    }
  }
}

/* Form */

form {
  margin: 0 0 _size(element-margin) 0;
}

label {
  color: _palette(fg-bold);
  display: block;
  font-size: 0.9em;
  font-weight: _font(weight-bold);
  margin: 0 0 (_size(element-margin) * 0.5) 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
  @include vendor('appearance', 'none');
  background: _palette(border-bg);
  border-radius: 0;
  border: none;
  border: solid 1px _palette(border);
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;

  &:invalid {
    box-shadow: none;
  }

  &:focus {
    border-color: _palette(accent1, bg);
    box-shadow: 0 0 0 1px _palette(accent1, bg);
  }
}

.select-wrapper {
  @include icon;
  display: block;
  position: relative;

  &:before {
    color: _palette(border);
    content: '\f078';
    display: block;
    height: _size(element-height);
    line-height: _size(element-height);
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: _size(element-height);
  }

  select::-ms-expand {
    display: none;
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  height: _size(element-height);
}

textarea {
  padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"], {
  @include vendor('appearance', 'none');
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;

  & + label {
    @include icon;
    color: _palette(fg);
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: _font(weight);
    padding-left: (_size(element-height) * 0.6) + 0.75em;
    padding-right: 0.75em;
    position: relative;

    &:before {
      background: _palette(border-bg);
      border: solid 1px _palette(border);
      content: '';
      display: inline-block;
      height: (_size(element-height) * 0.6);
      left: 0;
      line-height: (_size(element-height) * 0.575);
      position: absolute;
      text-align: center;
      top: 0;
      width: (_size(element-height) * 0.6);
    }
  }

  &:checked + label {
    &:before {
      background: _palette(accent5, bg);
      border-color: _palette(accent5, bg);
      color: _palette(accent5, fg-bold);
      content: '\f00c';
    }
  }

  &:focus + label {
    &:before {
      border-color: _palette(accent1, bg);
      box-shadow: 0 0 0 1px _palette(accent1, bg);
    }
  }
}

input[type="checkbox"] {
  & + label {
    &:before {
    }
  }
}

input[type="radio"] {
  & + label {
    &:before {
      border-radius: 100%;
    }
  }
}

::-webkit-input-placeholder {
  color: _palette(fg-light) !important;
  opacity: 1.0;
}

:-moz-placeholder {
  color: _palette(fg-light) !important;
  opacity: 1.0;
}

::-moz-placeholder {
  color: _palette(fg-light) !important;
  opacity: 1.0;
}

:-ms-input-placeholder {
  color: _palette(fg-light) !important;
  opacity: 1.0;
}

.formerize-placeholder {
  color: _palette(fg-light) !important;
  opacity: 1.0;
}

/* Box */

.box {
  @include padding(3em, 2em);
  background-color: #fff;
  margin-bottom: _size(element-margin);
  position: relative;

  &:before, &:after {
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &.bordered {

    &:before {
      background: linear-gradient(to right, _palette(accent1, bg) 50%, _palette(accent2, bg) 50%);
    }

    &:after {
      background: linear-gradient(to right, _palette(accent3, bg) 50%, _palette(accent4, bg) 50%);
    }
  }

  &.style1:after {
    background-color: _palette(accent1, bg);
  }

  &.style2:after {
    background-color: _palette(accent2, bg);
  }

  &.style3:after {
    background-color: _palette(accent3, bg);
  }

  &.style4:after {
    background-color: _palette(accent4, bg);
  }

  &.alt {
    border-radius: 0;
    padding: 0;
  }

  &.big {
    @include padding(7em, 3.5em);

    > header.major {
      padding: 0 0 5em;
    }
  }

  .image.full {
    margin-bottom: 3em;
    margin-left: -2em;
    width: calc(100% + (2em * 2));

    img {
      width: 100%;
    }
  }

  > .image.full:first-child {
    margin-top: -3em;
  }
}

/* Icon */

.icon {
  @include icon;
  border-bottom: none;
  position: relative;

  > .label {
    display: none;
  }

  &.major {
    display: block;
    font-size: 4.5em;
    line-height: 1;
    margin-bottom: 0.4em;
  }

  &.style1 {
    color: _palette(accent1, bg);
  }
  &.style2 {
    color: _palette(accent2, bg);
  }
  &.style3 {
    color: _palette(accent3, bg);
  }
  &.style4 {
    color: _palette(accent4, bg);
  }
}

/* Image */

.image {
  border: 0;
  display: inline-block;
  position: relative;

  img {
    display: block;
  }

  &.left {
    float: left;
    padding: 0 1.5em 1em 0;
    top: 0.25em;
  }

  &.right {
    float: right;
    padding: 0 0 1em 1.5em;
    top: 0.25em;
  }

  &.left,
  &.right {
    max-width: 40%;

    img {
      width: 100%;
    }
  }

  &.fit {
    display: block;
    margin: 0 0 _size(element-margin) 0;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &.main {
    display: block;
    margin: 0 0 (_size(element-margin) * 2) 0;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 _size(element-margin) 0;
  padding-left: 1.25em;

  li {
    padding-left: 0.25em;
  }
}

ul {
  list-style: disc;
  margin: 0 0 _size(element-margin) 0;
  padding-left: 1em;

  li {
    padding-left: 0.5em;
  }

  &.alt {
    list-style: none;
    padding-left: 0;

    li {
      border-top: solid 1px _palette(border);
      padding: 0.5em 0;

      &:first-child {
        border-top: 0;
        padding-top: 0;
      }
    }
  }

  &.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;

    li {
      display: inline-block;
      padding: 0 1em 0 0;

      &:last-child {
        padding-right: 0;
      }

      .icon {
        &:before {
          font-size: 2em;
        }
      }
    }
  }

  &.labeled-icons {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: (_size(element-margin) * 1.5);
      padding-left: 4em;
      position: relative;
    }

    .icon {
      cursor: default;
      display: block;
      font-size: 2em;
      left: 0;
      line-height: 1;
      position: absolute;
      top: 0;
    }
  }

  &.icons-grid {
    list-style: outside none none;
    padding: 0;
    position: relative;

    &:after {
      clear: both;
      content: '';
      display: block;
    }

    li {
      @include padding(6em, 7em);
      border-top: solid 1px _palette(border);
      display: block;
      float: left;
      width: 50%;
      min-height: 27em;

      &:nth-child(odd) {
        border-right: solid 1px _palette(border);
      }
    }
  }

  &.counters {
    list-style: outside none none;
    margin-bottom: 0;
    padding: 0;

    li {
      @include padding(0, 1.85em);
      display: inline-block;
      margin-bottom: _size(element-margin);
      min-width: 13em;

      strong {
        color: _palette(accent1, fg-bold);
        display: block;
        font-size: 2.5em;
        font-weight: _font(weight-bold);
        height: 2.5em;
        line-height: 1.8;
        margin-bottom: (_size(element-margin) * 0.25);
        padding: 0.35em;
        position: relative;

        &:after {
          border-left: solid _size(element-triangle) transparent;
          border-right: solid _size(element-triangle) transparent;
          border-top: solid _size(element-triangle) transparent;
          bottom: -_size(element-triangle);
          content: '';
          display: block;
          height: 0;
          left: 50%;
          margin-left: -_size(element-triangle);
          position: absolute;
          width: 0;
        }
      }

      &.style1 {
        strong {
          background-color: _palette(accent1, bg);

          &:after {
            border-top-color: _palette(accent1, bg);
          }
        }
      }

      &.style2 {
        strong {
          background-color: _palette(accent2, bg);

          &:after {
            border-top-color: _palette(accent2, bg);
          }
        }
      }

      &.style3 {
        strong {
          background-color: _palette(accent3, bg);

          &:after {
            border-top-color: _palette(accent3, bg);
          }
        }
      }

      &.style4 {
        strong {
          background-color: _palette(accent4, bg);

          &:after {
            border-top-color: _palette(accent4, bg);
          }
        }
      }
    }
  }

  &.actions {
    cursor: default;
    list-style: none;
    padding-left: 0;

    li {
      display: inline-block;
      padding: 0 (_size(element-margin) * 0.5) 0 0;
      vertical-align: middle;

      &:last-child {
        padding-right: 0;
      }
    }

    &.small {
      li {
        padding: 0 (_size(element-margin) * 0.25) 0 0;
      }
    }

    &.vertical {
      li {
        display: block;
        padding: (_size(element-margin) * 0.5) 0 0 0;

        &:first-child {
          padding-top: 0;
        }

        > * {
          margin-bottom: 0;
        }
      }

      &.small {
        li {
          padding: (_size(element-margin) * 0.25) 0 0 0;

          &:first-child {
            padding-top: 0;
          }
        }
      }
    }

    &.fit {
      display: table;
      margin-left: (_size(element-margin) * -0.5);
      padding: 0;
      table-layout: fixed;
      width: calc(100% + #{(_size(element-margin) * 0.5)});

      li {
        display: table-cell;
        padding: 0 0 0 (_size(element-margin) * 0.5);

        > * {
          margin-bottom: 0;
        }
      }

      &.small {
        margin-left: (_size(element-margin) * -0.25);
        width: calc(100% + #{(_size(element-margin) * 0.25)});

        li {
          padding: 0 0 0 (_size(element-margin) * 0.25);
        }
      }
    }

    &.uniform li > * {
      min-width: 12em;
    }
  }

  &.updates {
    list-style: none;
    padding-left: 0;

    li {
      margin-top: _size(element-margin);
      padding-left: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      margin: 0;
    }

    a {
      display: block;
    }

    .timestamp {
      color: _palette(fg);
      display: block;
      font-size: 0.9em;
      margin-top: 0.5em;
    }
  }
}

dl {
  margin: 0 0 _size(element-margin) 0;
}

/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 _size(element-margin) 0;
  width: 100%;

  tbody {
    tr {
      border: solid 1px _palette(border);
      border-left: 0;
      border-right: 0;

      &:nth-child(2n + 1) {
        background-color: _palette(border-bg);
      }
    }
  }

  td {
    padding: 0.75em 0.75em;
  }

  th {
    color: _palette(fg-bold);
    font-size: 0.9em;
    font-weight: _font(weight-bold);
    padding: 0 0.75em 0.75em 0.75em;
    text-align: left;
  }

  thead {
    border-bottom: solid 2px _palette(border);
  }

  tfoot {
    border-top: solid 2px _palette(border);
  }

  &.alt {
    border-collapse: separate;

    tbody {
      tr {
        td {
          border: solid 1px _palette(border);
          border-left-width: 0;
          border-top-width: 0;

          &:first-child {
            border-left-width: 1px;
          }
        }

        &:first-child {
          td {
            border-top-width: 1px;
          }
        }
      }
    }

    thead {
      border-bottom: 0;
    }

    tfoot {
      border-top: 0;
    }
  }
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
.button {
  @include vendor('appearance', 'none');
  @include vendor('transition', ('background-color #{_duration(transition)} ease-in-out', 'color #{_duration(transition)} ease-in-out'));
  background-color: _palette(accent5, bg);
  border-radius: 0;
  border: 0;
  color: _palette(accent5, fg-bold) !important;
  cursor: pointer;
  display: inline-block;
  font-weight: _font(weight-bold);
  height: 3.1em;
  letter-spacing: _font(letter-spacing);
  line-height: 3.1em;
  padding: 0 1.65em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    background-color: lighten(_palette(accent5, bg), 5);
  }

  &:active {
    background-color: darken(_palette(accent5, bg), 5);
  }

  &.icon {
    padding-left: 1.35em;

    &:before {
      margin-right: 0.5em;
    }
  }

  &.fit {
    display: block;
    margin: 0 0 (_size(element-margin) * 0.5) 0;
    width: 100%;
  }

  &.small {
    font-size: 0.8em;
  }

  &.big {
    font-size: 1.35em;
  }

  &.alt {
    background-color: transparent;
    border: 0;
    box-shadow: inset 0 0 0 1px _palette(border);
    color: _palette(fg-bold) !important;

    &:hover {
      background-color: _palette(border-bg);
    }

    &:active {
      background-color: _palette(border2-bg);
    }

    &.icon {
      &:before {
        color: _palette(fg-light);
      }
    }
  }

  &.special {
    background-color: _palette(accent1, bg);
    color: _palette(accent1, fg-bold) !important;

    &:hover {
      background-color: lighten(_palette(accent1, bg), 5);
    }

    &:active {
      background-color: darken(_palette(accent1, bg), 5);
    }
  }

  &.disabled,
  &:disabled {
    background-color: _palette(fg) !important;
    box-shadow: inset 0 -0.15em 0 0 rgba(0,0,0,0.15);
    color: _palette(bg) !important;
    cursor: default;
    opacity: 0.25;
  }
}

/* Wrapper */

.wrapper {
  @include padding(8em, 0);

  &.style1 {
    position: relative;

    &:before {
      background-color: _palette(border);
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: 50%;
      top: 0;
      width: 1px;
    }

    .box.alt {
      header.major {
        padding: 7em 1em 4em;
      }
    }
  }

  &.style2 {
    background-attachment: scroll, fixed;
    background-image: url('images/overlay.png'), url('../images/header.jpg');
    background-position: center center;
    background-size: cover;

    header {
      margin-bottom: (_size(element-margin) * 2);
    }

    h2 {
      border-color: _palette(accent5, border2);
      color: _palette(accent5, fg-bold);
    }
  }

  &.style3 {
    @include padding(7em, 0);
    background-color: #fff;
  }
}

body.is-touch {
  .wrapper {
    &.style2 {
      background-attachment: scroll;
    }
  }
}

/* Header */

body {
  padding-top: 4.5em;

  &.landing {
    padding-top: 0 !important;
  }
}

@include keyframes('reveal-header') {
  0% { top: -5em; }
  100% { top: 0; }
}

#logo {
  max-height: 4em;
  margin: 0 0 (_size(element-margin) * 0.5) 0;
}

#header {
  background-color: #fff;
  border-bottom: solid 1px _palette(border);
  box-shadow: 0px 0.0375em 0.125em 0px rgba(0,0,0,0.05);
  color: _palette(fg-bold);
  cursor: default;
  height: 4.5em;
  left: 0;
  line-height: 4.4em;
  position: fixed;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  z-index: 10000;

  &.reveal {
    @include vendor('animation', 'reveal-header 0.5s');
  }

  h1 {
    padding: 0.5em 1em;
    img { max-height: 3.5em;}
  }

  nav {
    height: inherit;
    line-height: inherit;
    position: absolute;
    right: 1.25em;
    top: 0;
    vertical-align: middle;

    > ul {
      list-style: none;
      margin: 0;
      padding-left: 0;

      > li {
        display: inline-block;
        margin-left: 2.5em;
        padding-left: 0;

        a {
          @include vendor('transition', 'color #{_duration(transition)} ease-in-out');
          color: _palette(fg);
          display: inline-block;
          text-decoration: none;

          &:hover {
            color: _palette(fg-bold);
          }
        }

        &:first-child {
          margin-left: 0;
        }

        .button {
          @include vendor('transition', ('background-color #{_duration(transition)} ease-in-out', 'color #{_duration(transition)} ease-in-out'));
          height: 3em;
          line-height: 2.9em;
          margin-bottom: 0;
          padding: 0 1.5em;
          position: relative;
          top: -0.075em;
          vertical-align: middle;
        }

        > ul {
          display: none;
        }
      }
    }
  }

  .container {
    position: relative;

    h1 {
      left: 0;
    }

    nav {
      right: 0;
    }
  }

  &.alt {
    @include vendor('animation', 'none');
    background-color: transparent;
    border: 0;
    box-shadow: none;
    height: 3.25em;
    line-height: 3.25em;
    position: absolute;

    h1 {
      display: none;
    }

    nav {
      right: 2em;
      top: 2em;

      a {
        color: _palette(accent5, fg-bold);

        &:active, &:hover {
          color: _palette(accent5, fg-bold);
        }
      }
    }

    .button {
      box-shadow: inset 0 0 0 1px _palette(accent5, border2);
      color: _palette(accent5, fg-bold) !important;

      &:hover {
        background-color: _palette(accent5, border-bg);
      }

      &:active {
        background-color: _palette(accent5, border2-bg);
      }
    }
  }
}

.dropotron {
  background-color: #fff;
  border: solid 1px _palette(border);
  box-shadow: 0px 0.0375em 0.125em 0px rgba(0,0,0,0.05);
  list-style: none;
  margin: -0.75em 0 0 0;
  min-width: 12em;
  padding: 0.75em 1em;

  > li {
    border-top: solid 1px _palette(border);
    padding: 0;

    a {
      color: _palette(fg-bold);
      display: block;
      font-size: 0.8em;
      letter-spacing: _font(letter-spacing);
      line-height: 3em;
      text-decoration: none;
      text-transform: uppercase;
    }

    &:first-child {
      border-top: 0;
    }
  }

  &.level-0 {
    margin: 1.5em 0 0 0;

    &:before {
      @include vendor('transform', 'rotate(45deg)');
      background: #fff;
      border-color: _palette(border);
      border-style: solid;
      border-width: 0;
      border-left-width: 1px;
      border-top-width: 1px;
      content: '';
      display: block;
      height: 1em;
      position: absolute;
      right: 2em;
      top: -0.5em;
      width: 1em;
    }
  }
}

/* Banner */

#banner {
  @include padding(15em, 0, (1em, 0, 0, 0));
  background-attachment: fixed,       fixed,       fixed;
  background-image: url("images/overlay2.png"), url("images/overlay3.svg"), linear-gradient(45deg, #366598, #113055);
  background-position: top left,      center center,     center center;
  background-size: auto,       cover,       cover;
  color: _palette(accent5, fg-bold);
  line-height: 1.75;
  text-align: center;

  :last-child {
    margin-bottom: 0;
  }

  h2 {
    img {
      max-width: 15em;
    }
  }

  p {
    strong {
      color: #ffffff;
      font-weight: 600;
    }
    font-size: 1.65em;
    margin-bottom: 1.75em;
  }
}

body.is-touch {
  #banner {
    background-attachment: scroll;
  }
}

#services {
  p, li { text-align: left; }
}

#sidebar {
  .sidebar-heading {
    border-bottom: 1px solid _palette(border);
  }
  h4 { margin-bottom: 0em; }
  .see-all {
    float: right;
    font-size: 0.6em;
    font-weight: normal;
    color: _palette(accent1, bg);
    &:hover { text-decoration: underline; }
  }
}

.recent-post-list {
  padding-left: 0;
  li {
    list-style: none;
    padding-left: 0;
  }
  .post-meta {
    font-size: 0.8em;
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.category-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.1em;
  li {
    display: inline;
    padding: 0 1em;
    margin: 0;
  }
}

.category-list {
  .category-name {
    margin-top: 2em;
    text-align: right;
  }

  .category-label {
    display: inline-block;
    padding: 0.2em 0.5em;
    border-radius: 0.2em;
    text-decoration: none;
    &.category-articles {
      background: _palette(accent1, bg);
      color: white;
    }
    &.category-advisories {
      background: _palette(accent2, bg);
      color: white;
    }
  }
}

.post-list {
  .post-title {
    margin-bottom: 0;
    a {
      color: _palette(fg-bold);
    }
  }
  .post {
    position: relative;
    text-align: left;
    &:last-child { margin-bottom: 4em; }
  }
  .post-date {
    display: block;
    border: 1px solid _palette(border);
    width: 4em;
    padding: 0.5em 0.2em;
    margin: 0 0 1em 1em;
    text-align: center;
    border-radius: 0.2em;
    float: right;
  }
  .post-author {
    color: lighten(_palette(fg), 15);
    margin-bottom: 0.5em;
    .author-name {
      color: darken(_palette(fg), 100);
    }
  }
}

.post p { color: darken(_palette(fg), 100); }
.post p img { max-width: 100%; }

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  margin: 0;

  li { 
    box-sizing: border-box;
    display: block; 
    width: 31.333333333%;
    clear: none;
    min-height: 10em;
    border: 1px solid _palette(bg);
    margin: 0 2% 1em 0;
    padding: 2em 1em;
    text-align: center;
    transition: 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-property: border;

    &:hover {
      border: 1px solid _palette(fg);

    }

    img {
      height: 5em;
      width: 100%;
    }
  }

  &.red-team li { width: 48%; }

  &:after, &:before {
    content: '';
    display: block;
    clear: both;
    height: 0;
  }
}

#team-list {

  .person {
    min-height: 250px;
  }

  .person-headshot {
    margin: 0.2em 0;
  }

  .person-headline {
    margin-bottom: 0.1em;
  }

  .person-role {
    font-size: 0.7em;
    color: darken(_palette(fg-light), 20%);
  }
}

/* Footer */

#footer {
  @include padding(8em, 0);
  background-color: darken(_palette(accent5, bg), 12);
  color: _palette(accent5, fg);

  h2, h3, h4, h5, h6 {
    color: _palette(accent5, fg-bold);
  }

  a:not(.button) {
    @include vendor('transition', 'color #{_duration(transition)} ease-in-out');
    color: _palette(accent5, fg);
    text-decoration: none;

    &:active, &:hover {
      color: _palette(accent5, fg-bold);
    }
  }

  .button {
    color: _palette(accent5, fg-bold) !important;
    border-color: _palette(accent5, border2);
  }

  .icon {
    color: _palette(accent5, fg-light);
  }

  .copyright {
    color: _palette(accent5, fg);
    font-size: 0.9em;
    margin: (_size(element-margin) * 1.5) 0 0 0;
    padding: 0;
    text-align: center;

    li {
      border-left: solid 1px _palette(accent5, border);
      display: inline-block;
      list-style: none;
      margin-left: 1.5em;
      padding-left: 1.5em;

      &:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
}

/* XLarge */

@include breakpoint(xlarge) {

  /* Basic */

  body, input, select, textarea {
    font-size: 11pt;
  }

  /* Wrapper */

  .wrapper {
    @include padding(6em, 0);

    &.style3 {
      @include padding(6em, 0);
    }
  }

  /* Banner */

  #banner {
    @include padding(13em, 0, (1em, 0, 0, 0));
  }

  /* Footer */

  #footer {
    @include padding(6em, 0);
  }

}

/* Large */

@include breakpoint(large) {

  /* Basic */

  body, input, select, textarea {
    font-size: 11pt;
  }

  /* Banner */

  #banner {
    @include padding(11em, 0, (1em, 0, 0, 0));

    h2 {
      font-size: 2.5em;
    }
  }

}

/* Medium */

#navPanel, #titleBar {
  display: none;
}

@include breakpoint(medium) {

  /* Basic */

  html, body {
    overflow-x: hidden;
  }

  body, input, select, textarea {
    font-size: 11pt;
  }

  header {
    br {
      display: none;
    }
  }

  /* Lists */

  ul {
    &.icons-grid {
      li {
        @include padding(4em, 2em);
      }
    }

    &.counters {
      li {
        @include padding(0, 1em);
        min-width: 9em;
      }
    }
  }

  /* Box */

  .box {
    &.big {
      @include padding(3em, 2.5em);

      header.major {
        padding: 3em 0 3em;
      }
    }
  }

  /* Wrapper */

  .wrapper {
    @include padding(4em, 0);

    &.style1 {
      .box.alt {
        header.major {
          padding: 6em 2em 3em;
        }
      }
    }

    &.style3 {
      @include padding(4em, 0);
    }
  }

  /* Header */

  body {
    padding-top: 44px;
  }

  #header {
    display: none;
  }

  /* Banner */

  #banner {
    @include padding(12em, 4em, (2em, 0, 0, 0));

    br {
      display: none;
    }
  }

  /* Footer */

  #footer {
    @include padding(4em, 0);

    .footer-info {
      text-align: center;
    }
  }

  /* Off-Canvas Navigation */

  #titleBar {
    display: block;
    height: 44px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: _misc(z-index-base) + 1;
    background-color: #fff;
    border-bottom: solid 1px _palette(border);
    box-shadow: 0 0.0375em 0.125em 0 rgba(0,0,0,0.05);
    color: _palette(fg-bold);
    line-height: 44px;
    text-transform: uppercase;

    .toggle {
      @include icon;
      height: 4em;
      left: 0;
      position: absolute;
      top: 0;
      width: 6em;
      z-index: 1;
      outline: 0;
      border: 0;
      color: inherit;
      text-decoration: none;

      &:before {
        border-right: solid 1px _palette(border);
        content: '\f0c9';
        display: block;
        height: 44px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 44px;
        color: inherit;
      }
    }

    .title {
      font-weight: _font(weight-heading);
      left: 0;
      letter-spacing: _font(letter-spacing);
      line-height: inherit;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;

      a {
        color: inherit;
        text-decoration: none;

        img {
          max-height: 44px;
          padding: 0.25em 0;
        }
      }
    }
  }

  #navPanel {
    @include vendor('backface-visibility', 'hidden');
    @include vendor('transform', 'translateX(#{_size(navPanel) * -1})');
    @include vendor('transition', ('transform #{_duration(navPanel)} ease'));
    display: block;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: _size(navPanel);
    z-index: _misc(z-index-base) + 2;
    background: #fff;
    border-right: solid 1px _palette(border);
    box-shadow: 0 0.0375em 0.125em 0 rgba(0,0,0,0.05);
    padding: 0 1em;
    text-transform: uppercase;

    .link {
      border: 0;
      border-top: solid 1px _palette(border2-bg);
      color: _palette(fg-bold) !important;
      display: block;
      font-size: 0.8em;
      height: 4em;
      letter-spacing: _font(letter-spacing);
      line-height: 4em;
      text-decoration: none;
      text-transform: uppercase;

      &:first-child {
        border-top: 0;
      }

      &.depth-0 {
        font-weight: _font(weight-bold);
      }

      .indent-1 { display: inline-block; width: 1.25em; }
      .indent-2 { display: inline-block; width: 2.5em; }
      .indent-3 { display: inline-block; width: 3.75em; }
      .indent-4 { display: inline-block; width: 5em; }
      .indent-5 { display: inline-block; width: 6.25em; }
    }
  }

  body {
    &.navPanel-visible {
      #navPanel {
        @include vendor('transform', 'translateX(0)');
      }
    }
  }

}

/* Small */

@include breakpoint(small) {

  .services-list li { width: 100% !important; }

  /* Basic */

  body, input, select, textarea {
    font-size: 11pt;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.15em;
  }

  h4 {
    font-size: 1em;
  }

  header.major {

    p {
      font-size: 1.15em;
    }
  }

  /* Lists */

  ul {
    &.icons-grid {
      li {
        @include padding(2em, 1.5em);
        width: 100%;
        min-height: auto;

        &:nth-child(odd) {
          border-right: 0;
        }
      }
    }

    &.counters {
      li {
        @include padding(0, 5%);
        min-width: 0;
        width: 45%;
        vertical-align: top;
      }
    }
  }

  /* Box */

  .box {
    @include padding(2em, 1em, (0, 0, -1em, 0));

    &.big {
      @include padding(2em, 1.5em);

      header.major {
        padding: 2em 0 2em;
      }
    }

    .image.full {
      margin-bottom: 2em;
      margin-left: -1em;
      width: calc(100% + (1em * 2));

      img {
        width: 100%;
      }
    }

    > .image.full:first-child {
      margin-top: -2em;
    }
  }

  /* Wrapper */

  .wrapper {
    @include padding(4em, 0);

    &.style1 {
      .box.alt {
        header.major {
          @include padding(4em, 2em);
        }
      }
    }

    &.style3 {
      @include padding(4em, 0);
    }
  }

  /* Banner */

  #banner {
    @include padding(6em, 2em, (1em, 0, 0, 0));

    h2 {
      font-size: 1.5em;
    }

    p {
      font-size: 1.15em;
    }
  }

  /* Footer */

  #footer {
    @include padding(4em, 0, (0, 0, 2em, 0));

    .copyright {
      li {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
        display: block;
      }
    }
  }

}

/* XSmall */

@include breakpoint(xsmall) {

  /* Basic */

  html, body {
    min-width: 320px;
  }

  body, input, select, textarea {
    font-size: 11pt;
  }

  /* List */

  ul {
    &.actions {
      margin: 0 0 _size(element-margin) 0;

      li {
        display: block;
        padding: (_size(element-margin) * 0.5) 0 0 0;
        text-align: center;
        width: 100%;

        &:first-child {
          padding-top: 0;
        }

        > * {
          margin: 0 !important;
          width: 100%;

          &.icon {
            &:before {
              margin-left: -2em;
            }
          }
        }
      }

      &.small {
        li {
          padding: (_size(element-margin) * 0.25) 0 0 0;

          &:first-child {
            padding-top: 0;
          }
        }
      }
    }
  }

  /* Button */

  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  .button {
    padding: 0;
  }

  /* Box */

  .box {
    &.big {
      @include padding(2em, 1em);

      header.major {
        padding: 1em 1em 1em;
      }
    }
  }

  /* Wrapper */

  .wrapper {
    @include padding(3em, 0);

    &.style1 {
      .box.alt {
        header.major {
          @include padding(3em, 1em);
        }
      }
    }

    &.style3 {
      @include padding(3em, 0);
    }
  }

  /* Banner */

  #banner {
    p {
      font-size: 1.25em;
    }
  }

  /* Footer */

  #footer {
    .footer-info {
      text-align: left;
    }
  }

}
